import * as React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { Link } from "gatsby"

const PublicMeetingPage = () => (
  <Layout>
    <Seo title="Shows" />
    <div className="full-width bg-hctv-blue text-white px-10 md:px-20 lg:px-24 xl:px-40 py-8">
      <div className="full-width px-10 md:px-20 lg:px-24 xl:px-40 flex gap-6 overflow-x-auto font-black text-lg hide-scroll uppercase flex-nowrap whitespace-nowrap flex-row flex-shrink-0">
        <Link to="/community" activeClassName="active-white">
          Community Programs
        </Link>
        <Link to="/public-meetings" activeClassName="active-white">
          Public Meetings
        </Link>
        <Link to="/community-info" activeClassName="active-white">
          Community Info
        </Link>
      </div>
    </div>
    <div className="embed-container full-width">
        <iframe src="https://hudsoncommunitytv.viebit.com/live/?v=afadd1eb-4c51-4310-9be1-7425fa625e6e&s=false" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen title="HCTV Community Ch. 1021"></iframe>
    </div>
    <h1 className="font-black text-4xl mt-10">HCTV Public Meetings Ch. 1022</h1>
    <div className="flex gap-4 mb-6">
        <p className="text-gray-500">Live</p>
    </div>
  </Layout>
)

export default PublicMeetingPage
